<template>
  <div class="q-px-md">
    <div class="row no-wrap q-gutter-md">
      <SummaryCard
        v-for="(data, index) in channelSummaries"
        :selectedVision="'campaign'"
        :data="data"
        :key="index + data.title"
      />
    </div>

    <div class="row q-col-gutter-x-md q-mt-lg">
      <div class="col-6">
        <SummaryTable class="q-mb-lg" :data="campaignChannelResults" :columns="campaignTableColumns" />
        <div class="row q-col-gutter-x-sm">
          <div class="col-6">
            <SummaryLineChart
              :id="'commission-full-daily-value-chart-channel'"
              :title="'Conversões por dia'"
              :dataProperty="'transactions'"
              :data="channelTransactionByDay"
            />
          </div>
          <div class="col-6">
            <SummaryPizzaChart
              :id="'commission-by-status-chart-channel'"
              :data="channelTransactionByStatus"
              :title="'Aprovação'"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <DoubleBarChart :data="performanceByMonth" />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import DashboardResumeService from '@/services/DashboardResume.js';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import DoubleBarChart from '@/components/smartDashboard/SummaryDoubleBarChart.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';

export default {
  name: 'ChannelVision',
  components: {
    SummaryCard,
    SummaryTable,
    DoubleBarChart,
    SummaryLineChart,
    SummaryPizzaChart
  },

  mixins: [DashboardResumeService],
  props: {
    filters: {
      require: true,
      type: [Object]
    },
    period: {
      require: true,
      type: [String]
    },
    ignoreTestCampaigns: {
      require: true,
      type: [Boolean, String]
    }
  },

  data() {
    return {
      campaignChannelResults: [],
      channelSummaries: {
        comission_full: { title: 'Comissão Full', value: '', color: '#747474', icon: 'mdi-trending-up', type: 'money' },
        cost: { title: 'Custo', value: '', color: '#747474', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', color: '#747474', icon: 'mdi-chart-line', type: 'money' },
        margin_percentage: {
          title: '% Margem',
          value: '',
          color: '#747474',
          icon: 'mdi-percent-outline',
          type: 'percent'
        },
        transactions: { title: 'Conversões', value: '', color: '#FE7FEB', type: 'quantity' },
        campaigns: { title: 'Campanhas', value: '', color: '#FE7FEB', type: 'quantity' },
        average_ticket: { title: 'Ticket Médio', value: '', color: '#FE7FEB' }
      },
      campaignTableColumns: [
        {
          name: 'campaign_name',
          label: 'Nome da Campanha',
          field: 'campaign_name',
          align: 'center',
          sortable: true
        },
        {
          name: 'transactions',
          label: 'Conversão',
          field: 'transactions',
          align: 'center',
          sortable: true
        },
        {
          name: 'comission_full',
          label: 'Comissão Full',
          field: row => parseFloat(row.comission_full),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'cost',
          label: 'Custo',
          field: row => parseFloat(row.cost),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'margin',
          label: 'Margem',
          field: row => parseFloat(row.margin),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'share_full',
          label: '% Share full',
          field: row => parseFloat(row.share_full),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => val + ' %'
        },
        {
          name: 'validation',
          label: 'Taxa de Validação',
          field: (row) => parseFloat(row.validation),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val, row) => parseFloat(val).toLocaleString('pt-br') + ' %'
        },
        {
          name: 'share_margin',
          label: '% Share margem',
          field: row => parseFloat(row.share_margin),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val, row) => val + ' %'
        }
      ],

      performanceByMonth: [],
      channelTransactionByDay: [],
      channelTransactionByStatus: []
    };
  },

  methods: {
    async getChannelDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        filterString += 'channel_id' + '%3D' + this.filters.channel.value.id;

        if (this.filters.status.value !== '' && this.filters.status.value !== null) {
          filterString += '%26status' + '%3D' + this.filters.status.value;
        }

        const [dashboardResume, performanceByMonth, campaignChannelTable, transactionsByDay, transactionsByStatus] =
          await Promise.all([
            await this.getChannelVisionDashboardResume(filterString + '%26' + this.periodString()),
            await this.getChannelTransactionsByMonth(filterString),
            await this.getCampaignByChannel(filterString + '%26' + this.periodString()),
            await this.getChannelTransactionsByDay(filterString),
            await this.getChannelTransactionsByStatus(filterString + '%26' + this.periodString())
          ]);

        Object.keys(this.channelSummaries).forEach((key) => {
          this.channelSummaries[key].value = dashboardResume.data[key];
        });

        this.performanceByMonth = performanceByMonth.data;
        this.campaignChannelResults = campaignChannelTable.data;
        this.channelTransactionByDay = transactionsByDay.data;
        this.channelTransactionByStatus = transactionsByStatus.data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    resetChannelVisionData() {
      Object.values(this.channelSummaries).map((val) => (val.value = ''));
    }
  },

  mounted() {
    this.resetChannelVisionData();
  }
};
</script>

<style></style>
