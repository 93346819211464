<template>
  <div class="q-px-md">
    <div class="row no-wrap q-gutter-md">
      <SummaryCard
        v-for="(data, index) in campaignSummaries"
        :selectedVision="'campaign'"
        :data="data"
        :key="index + data.title"
      />
    </div>

    <div class="row q-col-gutter-x-md q-mt-lg">
      <div class="col-6">
        <SummaryTable class="q-mb-lg" :data="campaignChannelResults" :columns="channelTableColumns" />

        <div class="row q-col-gutter-x-sm">
          <div class="col-6">
            <SummaryLineChart
              :id="'commission-full-daily-value-chart-campaign'"
              :title="'Conversões por dia'"
              :dataProperty="'transactions'"
              :data="campaignCommissionByDay"
            />
          </div>
          <div class="col-6">
            <SummaryPizzaChart
              :id="'commission-by-status-chart-campaign'"
              :data="campaignCommissionPerStatus"
              :title="'Aprovação'"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <SummaryDoubleBarChart :data="campaignTransactionsByMonth" />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';
import SummaryDoubleBarChart from '@/components/smartDashboard/SummaryDoubleBarChart.vue';
import DashboardResumeService from '@/services/DashboardResume.js';

export default {
  name: 'CampaignVision',
  components: {
    SummaryCard,
    SummaryTable,
    SummaryPizzaChart,
    SummaryLineChart,
    SummaryDoubleBarChart
  },

  mixins: [DashboardResumeService],
  props: {
    filters: {
      require: true,
      type: [Object]
    },
    period: {
      require: true,
      type: [String]
    },
    ignoreTestCampaigns: {
      require: true,
      type: [Boolean, String]
    }
  },
  data() {
    return {
      campaignCommissionByDay: [],
      campaignCommissionPerStatus: [],
      campaignTransactionsByMonth: [],
      campaignChannelResults: [],
      campaignSummaries: {
        comission_full: { title: 'Comissão Full', value: '', color: '#747474', icon: 'mdi-trending-up', type: 'money' },
        cost: { title: 'Custo', value: '', color: '#747474', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', color: '#747474', icon: 'mdi-chart-line', type: 'money' },
        margin_percentage: {
          title: '% Margem',
          value: '',
          color: '#747474',
          icon: 'mdi-percent-outline',
          type: 'percent'
        },
        transactions: { title: 'Conversões', value: '', color: '#FE7FEB', type: 'quantity' },
        affiliates: { title: 'Afiliados', value: '', color: '#FE7FEB', type: 'quantity' },
        average_ticket: { title: 'Ticket Médio', value: '', color: '#FE7FEB' }
      },
      channelTableColumns: [
        {
          name: 'channel_name',
          label: 'Canal',
          field: 'channel_name',
          align: 'center',
          sortable: true
        },
        {
          name: 'transactions',
          label: 'Conversão',
          field: 'transactions',
          align: 'center',
          sortable: true
        },
        {
          name: 'average_ticket',
          label: 'Ticket Médio',
          field: row => parseFloat(row.average_ticket),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'comission_full',
          label: 'Comissão Full',
          field: row => parseFloat(row.comission_full),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'cost',
          label: 'Custo',
          field: row => parseFloat(row.cost),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'margin',
          label: 'Margem',
          field: row => parseFloat(row.margin),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => 'R$ ' + parseFloat(val).toLocaleString('pt-br')
        },
        {
          name: 'share_full',
          label: '% Share full',
          field: row => parseFloat(row.share_full),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => val + ' %'
        },

        {
          name: 'validation',
          label: 'Taxa de Validação',
          field: (row) => parseFloat(row.validation),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val, row) => parseFloat(val).toLocaleString('pt-br') + ' %'
        },
        {
          name: 'share_margin',
          label: '% Share margem',
          field: row => parseFloat(row.share_margin),
          align: 'center',
          sortable: true,
          //eslint-disable-next-line
          format: (val) => val + ' %'
        }
      ]
    };
  },

  methods: {
    async getCampaignDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        filterString += 'campaign_id' + '%3D' + this.filters.campaign.value.id;

        if (this.filters.status.value !== '' && this.filters.status.value !== null) {
          filterString += '%26status' + '%3D' + this.filters.status.value;
        }

        const [
          dashboardResume,
          campaignChannelTable,
          dailyCommisionData,
          campaignCommissionByStatus,
          campaignTransactionsByMonth
        ] = await Promise.all([
          await this.getCampaignVisionSmartDashboardResume(filterString + '%26' + this.periodString()),
          await this.getCampaignChannelResume(filterString + '%26' + this.periodString()),
          await this.getCampaignCommissionByDay(filterString),
          await this.getCampaignCommissionByStatus(filterString + '%26' + this.periodString()),
          await this.getCampaignTransactionsByMonth(filterString)
        ]);

        Object.keys(this.campaignSummaries).forEach((key) => {
          this.campaignSummaries[key].value = dashboardResume.data[key];
        });

        this.campaignChannelResults = campaignChannelTable.data;
        this.campaignCommissionByDay = dailyCommisionData.data;
        this.campaignCommissionPerStatus = campaignCommissionByStatus.data;
        this.campaignTransactionsByMonth = campaignTransactionsByMonth.data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    resetCampaignVisionData() {
      Object.values(this.campaignSummaries).map((val) => (val.value = ''));
    }
  },

  mounted() {
    this.resetCampaignVisionData();
  }
};
</script>

<style></style>
